<template>
	<div class="header container-fluid float-start w-100">
		<div class="d-flex flex-row justify-content-between align-items-center">
			<div class="header-left flex-fill row p-3">
				<div class="d-flex align-items-center mr-3" style="width:160px;">
					<router-link to="/">
						<img src="@/assets/img/logo-wz.png" class="img-fluid" />
					</router-link>
				</div>
				<div class="col-3 border-left p-3 text-muted">
					<h4 class="m-0">{{title}}</h4>
				</div>
			</div>
			<div v-if="isLrShow" class="header-right f-16">
				<router-link to="/login">登录</router-link><span class="ml-3 mr-3 f-16">|</span><link to="/register">注册</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			isLrShow: {
				type: Boolean,
				default: false
			},
			title: {
				type: String,
				default: ''
			}
		}
	}
</script>

<style scoped>
	@media screen and (max-width:666px) {
		.header-right {
			display: none;
		}
		.header-left h4 {
			width: 300px;
		}
	}
</style>
