<template>
	<div class="register">
		<simple-header title="找回交易密码" :isLrShow="true"></simple-header>
		<div class="findpwd container-fluid" style="height:460px">
			<div class="row justify-content-center pt-4 pb-4 h-100 align-items-center">
				<div class="border rounded p-4 h-100 bg-white" style="width: 800px;">
					<form>
						<p>如果您忘记了交易密码，请输入手机号码重设交易密码，若你忘记账号，请联系客服申述。</p>
						<b-form-input class="mt-4 rounded" v-model="mobile" placeholder="请输入您绑定的手机号码"
							maxlength='11' autocomplete='off'></b-form-input>
						<div class="clearfix mt-4">
							<div class="float-left" style="width: 73%;">
								<b-form-input type="text" class="rounded w-100" v-model="code" placeholder="短信验证码"
									maxlength='6' autocomplete='off'></b-form-input>
							</div>
							<div></div>
							<div class="float-right w-25">
                <b-button class="width-120" @click="getCode"
                          :variant="seconds == 0 ? 'success' : 'secondary'"
                          :disabled="seconds > 0">{{seconds == 0 ? '获取动态码' : seconds + 'S后重试'}}
                </b-button>
							</div>
						</div>
						<b-form-input class="rounded mt-4" type="password" v-model="transPassword" placeholder="请输入您的新交易密码 (6位数字，区别于登录密码)"
							maxlength='6' autocomplete='off'></b-form-input>
						<b-button class="mt-4 w-100" variant="success" @click="findTradePassword" >立即找回</b-button>
					</form>
				</div>
			</div>
		</div>
		<icp-footer></icp-footer>
	</div>
</template>

<script>
	import simpleHeader from '@/components/header/simple-header';
	import icpFooter from '@/components/footer/icp-footer';
	export default {
		name: 'transpasswordfind',
    data(){
		  return {
        mobile: '',
        transPassword:'',
        code:'',
        seconds: 0,
        timer: null,
      }
    },
   methods:{
     verifyMobile() {
       if (this.mobile == '') {
         this.$model.toast('请填写预留手机号码',2000)
         return false
       } else {
         if (!this.$test.mobile(this.mobile)) {
           this.$model.toast('请填写正确的预留手机号码',2000)
           return false
         }
       }
       return true;
     },
      verify() {
        if(!this.verifyMobile()) {
            return false;
        }
        if (this.code == "") {
            this.$model.toast('请输入短信验证码',2000)
            return false;
        } 
        if (this.transPassword == "") {
            this.$model.toast('请输入您的新交易密码',2000)
            return false;
        } else {
          if(this.transPassword.length < 6 || !this.$test.number(this.transPassword)) {
            this.$model.toast('交易密码为6位数字,且区别于登录密码',2000)
            return false;
          }
        }
        return true;
      },
      getCode() {
       if (this.verifyMobile()) {
          this.seconds = 60;
          this.timer = setInterval(() => {
            // 执行加或减函数
            if (this.seconds > 0) {
              this.seconds--;
            } else {
              clearInterval(this.timer);
            }
          }, 1000);
       }
     },
     findTradePassword(){
        if(this.verify()) {
         this.$http .post(this.$api.user.findTradePassword, {param: {
             mobile: this.mobile,
             transPassword: this.transPassword,
             code: this.code
           }
         }).then((res) => {
           if (res.code == 1) {
             this.$model.toast("找回交易密码成功");
             Object.assign(this.$data, this.$options.data());
           }
         });
        }
      }
   },
    components: {
			icpFooter,simpleHeader
		}
	}
</script>


<style scoped>
	.findpwd {
		height: calc(100vh - 172px);
		min-height: calc(100vh - 172px);
		background-color: #F7F8FA;
	}
	form {
		margin: 30px auto;max-width: 480px;
	}
	@media screen and (max-width:506px) {
		.findpwd {
			min-width: 506px;
		}
	}
</style>
